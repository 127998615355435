import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import LineChart from '../../charts/LineChart01';
import Icon from '../../images/icon-01.svg';
import EditMenu from '../EditMenu';
import axios from 'axios';

// Import utilities
import { tailwindConfig, hexToRGB } from '../../utils/Utils';

function DashboardCard01() {
  
  // const [count, setCount] = useState('');

  // const getCount = async () => {
  //   let counter = await axios.get('http://localhost:5000/certificate/');
  //   setCount(counter);
  // };

  // useEffect(() => {
  //   getCount();
  // }, []); // triggered on route change

  

  return (
    <>
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <div className="px-5 pt-5">
        <header className="flex justify-between items-start mb-2">
          {/* Icon */}
          <img src={Icon} width="32" height="32" alt="Icon 01" />
          {/* Menu button */}
        </header>
        <h2 className="text-lg font-semibold text-gray-800 mb-2">Certificates</h2>
        <div className="text-xs font-semibold text-gray-400 uppercase mb-1">Total</div>
        <div className="flex items-start">
          <div className="text-3xl font-bold text-gray-800 mr-2">Certificates created: 24</div>
        </div>
      </div>
    </div>
    </>
  );
}

export default DashboardCard01;
